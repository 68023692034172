import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { FastField as BaseField } from 'formik';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Select from './Select';

const propTypes = {
  label: string,
  name: string.isRequired,
  options: arrayOf(shape({})),
  type: string.isRequired,
};

const defaultProps = {
  label: undefined,
  options: [],
};

const Field = ({ label, name, options, type, ...props }) => (
  <BaseField name={name}>
    {({ field, form, meta }) => {
      const feedback = meta.error && meta.touched ? meta.error : undefined;
      const isValid = !(meta.error && meta.touched);

      const handleSelectChange = (o) => {
        form.setFieldValue(name, o);
      };

      switch (type) {
        case 'select':
          return (
            <Select
              {...field}
              label={label}
              feedback={feedback}
              options={options}
              onChange={handleSelectChange}
              onBlur={() => form.setFieldTouched(name)}
              {...props}
            />
          );
        case 'textarea':
          return (
            <Form.Field
              isValid={isValid}
              feedback={feedback}
              label={label}
              type={null}
              as="textarea"
              {...field}
              {...props}
            />
          );
        default:
          return (
            <Form.Field
              isValid={isValid}
              feedback={feedback}
              label={label}
              type={type}
              {...field}
              {...props}
            />
          );
      }
    }}
  </BaseField>
);

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export default Field;
