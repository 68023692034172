import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import BaseButton from '@bit/medicalwebexperts.mwe-ui.button';

const StyledButton = styled(BaseButton)(
  css({
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

const Button = forwardRef((props, ref) => {
  return <StyledButton ref={ref} corner="square" {...props} />;
});

export default Button;
